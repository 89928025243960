<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="auto">
        <CButton color="outline-primary" @click="openMarket(market)">
          <font-awesome-icon icon="arrow-left" />
        </CButton>
      </CCol>
      <CCol sm="auto"><div class="ui-type-display-lg">{{ $t('family.market.showcase.cart.manual.title') }}</div></CCol>
    </CRow>

    <div class="loading text-center" v-if="loading">
      <CSpinner color="primary" size="lg" />
    </div>

    <div v-else-if="market">
      <MarketCart :market="market" :loadProducts="true" />
    </div>
  </div>
</template>

<script>
import { GetDataManagerNew, ApiCall, Query, Predicate } from "../../../../ds";
import MarketCart from "./MarketCart.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "UiMarketCartDetailManual",
  components: {
    MarketCart,
  },

  data() {
    return {
      loading: false,
      market: undefined,
    };
  },

  computed: {
    ...mapState("shop", ["shop"]),

    ...mapGetters("connections", ["familyId"]),
  },

  mounted() {
    this.getMarket();
  },

  watch: {
    currentPage: function () {
      this.getMarket();
    },
  },

  methods: {
    getMarket() {
      const self = this;
      self.loading = true;
      const predicate = new Predicate(
        "market.id",
        "equal",
        self.$route.params.marketId
      );
      const query = new Query().where(predicate);
      ApiCall(
        GetDataManagerNew("family_markets", [this.familyId]),
        query,
        (response) => {
          self.market = response.result[0];
          self.loading = false;
        },
        (e) => {
          console.error(e);
          self.loading = false;
        }
      );
    },

    openMarket(market) {
      this.$store.commit("shop/setMarket", market);
      this.$store.commit("shop/setCart", {});
      this.$router.push({
        name: "RoleOrdersNew",
        params: { id: this.$store.state.role.id },
      });
    },
  },
};
</script>
